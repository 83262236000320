import { ReactElement } from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

function IndexPage(): ReactElement {
  return (
    <Layout>
      <SEO title="Home" />
    </Layout>
  );
}

export default IndexPage;
